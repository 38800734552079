@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); 

/* body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
} */

#root {
  height: 100%;
  min-height: 100%;
}

.main {
  background: rgb(15, 16, 27);
}


.commentBubble {
  padding: 4px;
  border-radius: 100px 100px 100px 0px;
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.commentBubbleAvatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: block; 
}

